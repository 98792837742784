@use "../../styles/partials/colors.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.members {
  display: flex;
  flex-direction: column;

  @include desktop {
    align-items: center;
    // height: 40vw;
    max-height: 600px;
    width: 70%;
    margin: auto;

    background-color: white;
  }

  &__container {
    display: flex;

    width: 100%;
    @include desktop {
    }
  }

  &__category {
    // border: solid 1px black;
    cursor: pointer;
    height: 4.6rem;
    color: rgba(255, 255, 255, 0.614);
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $rf-grey;

    @include desktop {
      margin-right: 2rem;
      width: 30%;
      max-width: 20rem;
    }

    &:hover {
      color: white;
    }

    &--active {
      height: 4rem;
      background-color: $rf-green;
      border: solid 5px $rf-green;
      color: white;
    }
  }

  &__card {
    cursor: pointer;
    border: solid 1px $rf-grey;
    padding: 1rem 0;
    background-color: white;

    &:hover {
      background-color: $rf-green;
      color: $rf-white;
    }
  }

  &__desktop-wrapper {
    display: flex;
    flex-wrap: wrap;
    @include desktop {
      margin-top: 2rem;
      height: calc(100% - 74px);
      max-height: 800px;
      width: 100%;
    }
  }

  &__mobile-list {
    // padding-top: 34.27rem;

    height: 300px;
    overflow-y: scroll;

    @include desktop {
      display: none;
    }
  }

  &__desktop-list {
    display: none;

    @include desktop {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 30%;
      overflow-y: scroll;
    }
  }

  &__category-container {
    display: flex;
    width: 100%;
  }

  &__desktop-search {
    display: none;
    @include desktop {
      @include search;

      display: flex;
      margin: 0;
      width: 35%;
    }
  }

  &__mobile-search {
    @include search;

    @include desktop {
      display: none;
    }
  }
}
