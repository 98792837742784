@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins.scss" as *;

.popup {
  &__overlay {
    @include overlay;
  }

  &__active {
    @include active;
  }
  &__active-image {
    @include active-image;
  }
  &__left {
    @include left;
  }
  &__right {
    @include right;
  }
  &__close {
    @include close;
  }
}
