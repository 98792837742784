@use "../../styles/partials/colors.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.join {
  height: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40%;
    width: 800px;

    @include tablet {
      flex-direction: row;
      padding-top: 220px;
    }
  }

  &__button {
    @include button-styling;
    height: 150px;
    width: 300px;
    margin-bottom: 5%;
    @include tablet {
      margin-bottom: 10%;
      height: 230px;
    }
  }

  &__link {
    color: white;
    text-decoration: none;
  }
}
