@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins.scss" as *;

.board {
  @include padding-horizontal-outer;
  box-sizing: border-box;
  background-color: $rf-grey;
  color: $rf-white;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  // border: solid 1px red;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  @include tablet {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @include desktop {
    margin-top: 4rem;
    padding-bottom: 1.5rem;
  }

  &__header {
    padding: 1rem;
  }

  &__card {
    width: 100%;
    background-color: $rf-green;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0.5rem;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 90px;
    background-image: linear-gradient($rf-grey, $rf-grey);

    &:hover {
    }

    @include tablet {
      width: 20%;
      min-width: 12rem;
      max-width: 30rem;
      max-height: 18rem;
      padding-bottom: 3rem;
    }
  }

  &__pic {
    display: flex;
    margin-top: 0.5rem;

    @include tablet {
      margin-top: 3rem;
    }
  }

  &__photo {
    height: 10rem;
    width: 10rem;
    border-radius: 100px;
    object-fit: contain;
    background: white;

    @include tablet {
      height: 8rem;
      width: 8rem;
    }
  }

  &__info {
    padding: 1rem 0 1rem 0;
  }

  &__title {
    margin: 0;
    font-size: 1rem;
  }

  &__name {
    font-size: 1rem;
  }

  &__company {
    font-size: 0.7rem;
  }

  &__phone {
    font-size: 0.7rem;
  }

  &__email {
    font-size: 0.7rem;
    text-decoration: none;
    color: white;
  }
}
