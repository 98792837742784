@use "../../styles/partials/colors.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.nav-list {
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  // margin-left: auto;

  @include tablet {
    width: 100%;
    max-width: 40rem;
    flex-direction: row;
    justify-content: space-between;
  }

  @include desktop {
    width: 45rem;
  }

  &__item {
    cursor: pointer;
    color: #006e50;
    font-size: 1rem;
    font-weight: 700;
    list-style: none;
    transition: 0.5s;
  }

  &__link {
    color: $rf-green;
    text-decoration: none;
    // padding-right: 0.5rem;

    &:hover {
      @include tablet {
        padding-top: 2px;
        padding-bottom: 2px;
        color: $rf-green-highlight;
        border-bottom: 2px $rf-green-highlight solid;
      }
    }

    &:active {
      padding-top: 2px;
      padding-bottom: 2px;
      border-bottom: 2px $rf-white solid;
    }
  }
}
