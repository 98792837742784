$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;


// Mobile padding
$mobile-outer-padding: 1rem;
$mobile-inner-padding: 1.5rem;

// tablet padding
$tablet-outer-padding: 2rem;
$tablet-inner-padding: 2rem;

// desktop padding
$desktop-outer-padding: 8.125rem;
$desktop-inner-padding: 2.5rem;