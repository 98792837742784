@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.featured {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }
  &__card {
    display: flex;
    background-color: green;
    width: 100%;
    background-size: cover;
    background-position: center top 37%;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
    // border: solid 1px $swa-grey;

    @include tablet {
      flex-direction: column;
    }

    @include desktop {
      margin: auto;
      width: 100%;
      height: 30vh;
    }
  }
  &__text {
    width: 90%;
    margin: 0 2rem 2rem;
    padding: 1rem 1rem;
    background-color: rgb(#00825f, 0.8);
    border: 3px solid;

    @include tablet {
      width: 50%;
    }

    @include desktop {
      width: 50%;
    }
  }

  &__info {
    padding-bottom: 0.5rem;
    font-weight: 700;
  }

  &__title {
    font-size: 1.5rem;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);

    @include tablet {
      font-size: 2rem;
    }
  }

  &__where {
    font-size: 0.9rem;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    font-weight: 700;

    margin: 0.5rem;

    @include tablet {
      font-size: 1rem;
    }
  }

  &__when {
    font-size: 0.9rem;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    color: white;
    margin: 0.5rem;
    font-weight: 700;

    @include tablet {
      font-size: 1rem;
    }
  }

  &__link {
    font-size: 0.8rem;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    color: white;
    margin: 0.5rem;
    transition: 0.3s;
    font-weight: 700;

    &:hover {
      color: rgb(230, 230, 230);
    }
    @include tablet {
      font-size: 1rem;
    }
  }

  &__register {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 9rem;
    height: 2.8rem;
    font-size: 0.8rem;
    background-color: $rf-green;
    color: #ffffff;
    font-weight: 700;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background-color: $rf-green-highlight;
    }

    &:active {
      transform: translate(7.1px, 7.1px);
    }
  }
}
