@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins.scss" as *;

.footer {
  // margin-top: auto;
  padding-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  color: white;
  background-color: $rf-green;
  justify-content: space-around;
  align-items: center;
  border-top: 3px solid $rf-white;
  height: 100%;
  max-height: 15rem;
  // overflow: hidden;

  &__container {
    width: 100%;
    display: flex;

    @include tablet {
      justify-content: center;
      justify-content: space-around;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    width: 45%;
    padding: 0 1rem;
    font-size: 0.8rem;
    padding-bottom: 1rem;

    @include tablet {
      width: 30%;
    }

    @include desktop {
      width: 20%;
    }

    &--address {
      text-align: left;
    }
    &--copyright {
      justify-content: center;
    }
    &--links {
      text-align: left;
    }
  }
  &__header {
    font-size: 1rem;
    padding-bottom: 0.4rem;
  }

  &__text {
    color: white;
    font-size: 0.6rem;
    padding-bottom: 0.5rem;
    @include tablet {
      font-size: 0.8rem;
      padding-bottom: 0.5rem;
    }
    &--hyperlinks {
      &:hover {
        text-shadow: 0 0 10px rgba(255, 255, 255, 1);
    }
    }
  }

  &__copyright {
    padding: 0 0.5rem 1rem;
  }
}
