@use "variables" as *;
@use "colors" as *;
@use "typography" as *;

@mixin tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin padding-horizontal-outer {
  padding-left: $mobile-outer-padding;
  padding-right: $mobile-outer-padding;

  @include tablet {
    padding-left: $tablet-outer-padding;
    padding-right: $tablet-outer-padding;
  }

  @include desktop {
    padding-left: $desktop-outer-padding;
    padding-right: $desktop-outer-padding;
  }
}

@mixin padding-horizontal-inner {
  padding-left: $mobile-inner-padding;
  padding-right: $mobile-inner-padding;

  @include tablet {
    padding-left: $tablet-inner-padding;
    padding-right: $tablet-inner-padding;
  }

  @include desktop {
    padding-left: $desktop-inner-padding;
    padding-right: $desktop-inner-padding;
  }
}

// Title/Headers
@mixin font-header {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;

  @include tablet {
    font-size: 32px;
    line-height: 40px;
  }
}

// Subheaders
@mixin font-subheader {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  @include tablet {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin button-styling {
  cursor: pointer;
  font-weight: 900;
  background-color: $rf-green;
  color: $rf-white;
  font-size: 1rem;
  border: none;
  width: 10rem;
  height: 3.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  &:hover {
    background-color: $rf-green-highlight;
  }
}

@mixin album {
  box-sizing: border-box;
  // padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $rf-green;
  color: $rf-white;

  @include tablet {
    align-items: flex-start;
  }
}

@mixin album-title {
  padding: 1rem;

  @include tablet {
    align-self: center;
  }
}

@mixin album-list {
  padding: 0 2rem 0 1.15rem;
  text-align: left;
}

@mixin album-item {
  text-decoration: none;
}

@mixin album-blurbs {
  margin-left: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

@mixin album-message {
  font-weight: 900;
  padding: 0 2rem 1rem 1rem;
}

@mixin album-images {
  cursor: pointer;
  padding: 0 1.5rem;
  padding-bottom: 1rem;
  background-color: $rf-white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @include tablet {
    padding: 0 0 1rem 0;
  }
}

@mixin album-container {
  display: flex;
  justify-content: center;
  align-items: center;

  @include tablet {
    width: 33%;
  }
}

@mixin album-image {
  width: 90%;
  padding-top: 1.5rem;

  @include tablet {
    padding-top: 0;
  }

  @include tablet {
    width: 94%;
    margin-top: 1rem;
  }
}

@mixin overlay {
  z-index: 1;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgb(0, 0, 0, 0.5);
}

@mixin active {
  z-index: 2;
  position: relative;
}
@mixin active-image {
  height: 70vw;
  @include tablet() {
    height: 60vw;
  }
}

@mixin photo-icons {
  cursor: pointer;
  background-color: rgb(0, 0, 0, 0.5);
  height: 3rem;
  position: absolute;
  @include tablet {
    height: 5rem;
  }
}
@mixin left {
  @include photo-icons;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
@mixin right {
  @include photo-icons;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
@mixin close {
  @include photo-icons;
  top: 0;
  right: 0;
}

@mixin search {
  outline: none;
  margin: 0.5rem;
  background-color: white;
  width: 95%;
  justify-self: flex-end;
  align-self: flex-end;
  margin-right: 0;
  height: 3rem;
  color: black;
  padding-left: 1rem;
  font-size: 1.2rem;
  border: solid 1px grey;

  &::placeholder {
    color: grey;
  }
  &:focus {
    border: solid 2px black;
  }
}
