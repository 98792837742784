@use "../../styles/partials/colors.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.nav {
  position: fixed;
  z-index: 1;
  display: flex;
  width: 100vw;
  flex-direction: column;
  background-color: white;
  border-bottom: 3px solid $rf-green;

  &__placeholder {
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: white;
    border-bottom: 3px solid $rf-green;
  }

  &__container {
    // box-sizing: border-box;
    display: flex;
    width: 100;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    height: 100px;
    margin: 1rem 0.5rem;

    @include tablet {
      height: 80px;
    }
  }

  &__mobile-wrapper {
    display: flex;

    @include tablet {
      display: none;
    }
  }

  &__tablet-wrapper {
    display: none;

    @include tablet {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      padding-right: 1rem;
    }
  }

  &__button {
    @include button-styling;

    margin-right: 2rem;
    width: 6rem;

    @include tablet {
      margin-right: 1rem;
      margin-left: 1rem;
      min-width: 6rem;
      max-width: 10rem;
      width: 10vw;
    }
    @include desktop {
      width: 10rem;
    }
    &--tablet {
      margin-left: 1.5rem;
      @include desktop {
        margin-left: 11%;
      }
    }
  }

  &__menu-icon {
    margin-right: 0.4rem;
  }
}
