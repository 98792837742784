@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/colors" as *;

.mission {
  position: relative;
  background-color: $rf-green;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  position: absolute;
  overflow-y: scroll;
  height: 50vh;
  width: 80%;
  top: 10%;
  left: 10%;
  z-index: 2;
  // border: 2px solid black;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  &::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $rf-green-highlight;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 1px solid black;
    border-radius: 10px;
    background: $rf-green;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $rf-green;
  }

  @include tablet {
    width: 60%;
    left: 20%;
  }

  &__info {
    padding-bottom: 0.5rem;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    height: 22px;
    right: 2%;
    top: 2%;

    @include tablet {
      height: 30px;
    }
  }

  &__title {
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }

  &__text {
    font-size: 0.6rem;

    @include tablet {
      font-size: 0.8rem;
      font-weight: 400;
      padding: 0.4rem 2rem;
    }
  }

  &__list {
    font-size: 1rem;
  }
}
