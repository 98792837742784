@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins.scss" as *;

.album {
  @include album;
  padding-top: 9rem;

  &__title {
    @include album-title;
  }

  &__list {
    @include album-list;
  }

  &__item {
    @include album-item;
  }

  &__blurbs {
    @include album-blurbs;
  }

  &__blurb {
  }

  &__message {
    @include album-message;
  }

  &__images {
    @include album-images;
  }

  &__container {
    @include album-container;
  }

  &__image {
    @include album-image;
  }
}
