@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.hero {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background-size: cover;
  background-position: center;
  border-top: solid 4px $rf-green;
  position: relative;
  z-index: 0;

  @include tablet {
    margin-top: 7.188rem;
  }

  &__overlay {
    position: absolute;
    top: 5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    max-width: 25rem;
    align-items: center;
    justify-content: center;
  }

  // &__section {
  //   border: solid 2px red;

  //   @include tablet {
  //     padding-bottom: 6rem;
  //   }

  //   @include desktop {
  //     padding: 0;
  //   }
  // }

  &__text {
    font-size: 0.8rem;
  }

  &__title {
    color: white;
    font-size: 1.25rem;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    padding-bottom: 2rem;

    @include tablet {
      font-size: 2rem;
    }
  }

  &__join {
    @include button-styling;
  }
}
