@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins.scss" as *;

.galleries {
  // height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $rf-green;
  width: 100%;
  padding: 1rem;
  padding-top: 10rem;
  // height: 800rem;
  @include desktop {
    height: 120rem;
  }

  @include tablet {
    padding-top: 10rem;
    flex-wrap: wrap;
    flex-direction: row;
  }

  @include desktop {
    padding-top: 10rem;
    flex-wrap: wrap;
    flex-direction: row;
  }

  &__card {
    @include album;
    display: flex;
    width: 100%;

    @include tablet {
    }
  }

  // &__card {
  //   width: 100%;

  //   @include tablet {
  //     width: 100%;
  //     margin: 1rem;
  //     object-fit: cover;
  //   }
  // }

  &__link {
    text-decoration: none;
  }

  &__cover {
    width: 98%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    @include tablet {
      height: 20rem;
    }
  }

  &__title {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    color: $rf-white;
  }
}
