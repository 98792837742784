@use "../../styles/partials/colors.scss" as *;
@use "../../styles/partials/mixins.scss" as *;

.mobile-nav-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding-bottom: 0.5rem;
  z-index: 2;
  transition: 3s;

  @include tablet {
    display: none;
  }

  &__item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    list-style: none;
    transition: 0s;

    &:hover {
      background-color: $rf-green;
    }

    &:active {
      padding-top: 2px;
      padding-bottom: 2px;
      // border-bottom: 2px $rf-white solid;
    }
  }
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: solid 1px black;
    height: 5rem;
    color: $rf-green;
    text-decoration: none;
    border-bottom: none;
    border-left: none;
    border-right: none;

    &:hover {
      color: white;
    }
    &--bottom {
      border-bottom: 1px black solid;
    }
  }
}
