@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins.scss" as *;

.gallery {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: no-wrap;
  background-color: $rf-white;
  width: 100%;
  padding: 1rem;
  padding-bottom: 0rem;

  @include tablet {
    flex-direction: row;
  }

  &__card {
    @include tablet {
      padding: 1rem;
      object-fit: cover;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__cover {
    width: 98%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    object-fit: cover;

    @include tablet {
      height: 15rem;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
      &:hover {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
      }
    }
  }

  &__title {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    color: $rf-green;
  }
}
